import React from 'react';

import ProductsCarousel, {
  ProductsCarouselProps,
} from 'components/Common/Carousel/ProductsCarousel/ProductsCarousel';
import { getSlideType } from 'services/carousel';
import { MOBILE_MAX, TABLET_MAX } from 'settings/device';

export type CarouselProps = Omit<
  ProductsCarouselProps,
  'splideOptions' | 'productPerPage'
> & {
  desktopProductPerPage: number;
  mobileProductPerPage?: number;
};

const Carousel = (props: CarouselProps): JSX.Element => {
  const { desktopProductPerPage, mobileProductPerPage, products } = props;
  const productsLenght = products.length;

  return (
    <ProductsCarousel
      splideOptions={{
        perPage: desktopProductPerPage,
        type: getSlideType(desktopProductPerPage, productsLenght),
        gap: '20px',
        breakpoints: {
          [TABLET_MAX]: {
            gap: '10px',
          },
          [MOBILE_MAX]: {
            perPage: mobileProductPerPage,
            ...(mobileProductPerPage && {
              type: getSlideType(mobileProductPerPage, productsLenght),
            }),
          },
        },
      }}
      {...props}
    />
  );
};

export default Carousel;
