import React, { PropsWithChildren } from 'react';

import HeaderLayout from 'layout/HeaderLayout';
import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import Ariane from 'components/Ariane/Ariane';

type Props = {
  emptyLayout?: boolean;
  arianeCurrentPath?: string;
};

export default function MainLayout({
  children,
  emptyLayout = false,
  arianeCurrentPath,
}: PropsWithChildren<Props>): JSX.Element {
  const AfibelFooter = React.lazy(
    () => import('../components/AfibelFooter/AfibelFooter')
  );

  return (
    <>
      <div>
        {!emptyLayout && <HeaderLayout />}
        {arianeCurrentPath && <Ariane currentPath={arianeCurrentPath} />}
        <>{children}</>
        <ScrollToTopButton />
      </div>
      <AfibelFooter />
    </>
  );
}
