import {
  DATE_INVALID,
  DATE_UNDER_18,
  DATE_VALID,
} from 'settings/birthdayValidValues';

export const regexUrlProductSheet = 'F-[0-9]*-'; //([0-9a-zA-Z]*|-*_*)*/P-[0-9]*-
export const regexUrlPageIndex = /\/I-Page([0-9])*\_[0-9]*/; // I-Page1_30
export const regexUrlCategoryPage = 'C-[0-9]*-';
export const regexWholeUrl =
  '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$';

export function isValidApiUrl(apiUrl: string, regex: string): boolean {
  return RegExp(regex).test(apiUrl);
}

export function isWholeUrl(apiUrl: string): boolean {
  return RegExp(regexWholeUrl).test(apiUrl);
}

export function isProductSheet(url: string): boolean {
  return RegExp(regexUrlProductSheet).test(url);
}

export function isCategoryPage(url: string): boolean {
  return RegExp(regexUrlCategoryPage).test(url);
}

export function isSearchPage(url: string): boolean {
  return RegExp('/Search/*|/Recherche/*').test(url);
}

export function isCheckoutPage(url: string): boolean {
  return RegExp('/checkout/*').test(url);
}

export function isDirectOrderPage(url: string): boolean {
  return RegExp('/direct_order|commande_directe').test(url);
}

export function isReactPage(url: string): boolean {
  return isProductSheet(url) || isCategoryPage(url) || isSearchPage(url);
}

export function isHomepage(url: string): boolean {
  return (
    url === '/' ||
    url === `/${process.env.REACT_APP_COUNTRY}` ||
    url === `/${process.env.REACT_APP_COUNTRY}/`
  );
}

export function isValidEmail(email: string): boolean {
  return email
    ? RegExp(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      ).test(email)
    : false;
}

export const isPostalCodeValid = (code?: string): boolean => {
  if (code === undefined) {
    // Required is handled outside
    return true;
  }
  if (!/^\d+$/.test(code)) {
    return false;
  }
  if (code.length !== 5) {
    return false;
  }
  if (code.length >= 2) {
    const prefix = parseInt(code.substring(0, 2));
    if (prefix < 1 || (prefix > 95 && prefix !== 97 && prefix !== 98)) {
      return false;
    }
  }
  if (code.length === 5) {
    const prefix = parseInt(code.substring(0, 2));
    const sub = parseInt(code.substring(2));

    if (prefix === 97 && (sub > 499 || sub === 133 || sub === 150)) {
      return false;
    }
    if (prefix === 98 && sub > 99) {
      return false;
    }
  }

  return true;
};

export const isPostalCodeValidWithoutOverseas = (code?: string): boolean => {
  if (code === undefined) {
    // Required is handled outside
    return true;
  }
  if (!/^\d+$/.test(code)) {
    return false;
  }
  if (code.length !== 5) {
    return false;
  }
  if (code.length >= 2) {
    const prefix = parseInt(code.substring(0, 2));
    if (!((prefix >= 1 && prefix <= 95) || prefix === 98)) {
      return false;
    }
  }
  if (code.length === 5) {
    const prefix = parseInt(code.substring(0, 2));
    const sub = parseInt(code.substring(2));

    if (prefix === 98 && sub > 99) {
      return false;
    }
    if (
      (prefix === 98 && sub >= 0 && sub <= 99) ||
      (prefix >= 1 && prefix <= 95 && sub >= 0 && sub <= 999)
    ) {
      return true;
    }
  }

  return false;
};

export function validateDateOfBirth(
  month: number,
  day: number,
  year: number
): typeof DATE_VALID | typeof DATE_UNDER_18 | typeof DATE_INVALID {
  const date = new Date(year, month - 1, day);

  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month - 1 ||
    date.getDate() !== day
  ) {
    return DATE_INVALID;
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const earliestDate = new Date('1900-01-01');
  earliestDate.setHours(0, 0, 0, 0);

  if (date >= today || date <= earliestDate) {
    return DATE_INVALID;
  }

  date.setFullYear(date.getFullYear() + 18);

  return date <= today ? DATE_VALID : DATE_UNDER_18;
}
