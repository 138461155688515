import { getUri } from 'services/generic';
import {
  discountPercentage,
  getBrandName,
  getDiscountPrice,
  getFinalPrice,
  getFullSubSticker,
  getMainSticker,
  getName,
  getStickerOpeco,
  getUniqPrice,
  getVisuelBack,
  getVisuelFace,
} from 'services/product';
import { ProductsType, ProductType } from 'types/CartMerchandisingType';
import {
  CarouselProductColorSwatchType,
  CarouselPushProductType,
} from 'types/Carousel';
import { SelectedProductAttributes } from 'types/ProductPageV2/Products';

export function formatPushProducts(
  products: ProductsType,
  additionalAttributes?: (product: ProductType) => Record<string, unknown>
): CarouselPushProductType[] {
  return products.map(product => {
    const { attributes, metadata } = product;
    const productAttributes = (attributes as unknown) as SelectedProductAttributes;

    return {
      brand: getBrandName(productAttributes),
      colorSwatch: metadata.colorSwatch as CarouselProductColorSwatchType[],
      commercialOperationSticker: getStickerOpeco(productAttributes),
      discountPercentage: discountPercentage(productAttributes),
      discountPrice: getDiscountPrice(productAttributes),
      price: getFinalPrice(productAttributes).toString(),
      productId: product.id,
      shortLabel: getName(productAttributes),
      stickerMain: getMainSticker(productAttributes),
      stickerSub: getFullSubSticker(productAttributes),
      uniquePrice: getUniqPrice(productAttributes),
      url: getUri(product.url),
      visuelBack: getVisuelBack(productAttributes),
      visuelFace: getVisuelFace(productAttributes),
      ...additionalAttributes?.(product),
    };
  });
}

export function getSlideType(perPage: number, itemLenght: number): string {
  return perPage < itemLenght ? 'loop' : 'slide';
}
