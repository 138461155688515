import React from 'react';
import classNames from 'classnames';

import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import HeaderLayout, { HeaderOptions } from 'layout/HeaderLayout';

import styles from './PageLayout.module.scss';

type Props = {
  emptyLayout?: boolean;
  headerOptions?: HeaderOptions;
  classes?: { container?: string };
};

export default function PageLayout({
  children,
  emptyLayout = false,
  headerOptions,
  classes,
}: React.PropsWithChildren<Props>): JSX.Element {
  const AfibelFooter = React.lazy(
    () => import('components/AfibelFooter/AfibelFooter')
  );

  return (
    <>
      <div>
        {!emptyLayout && <HeaderLayout options={headerOptions} />}
        <div
          className={classNames(
            styles.container,
            classes?.container
              ? styles[`container-${classes?.container}`]
              : undefined
          )}
        >
          {children}
        </div>
        <ScrollToTopButton />
      </div>
      <AfibelFooter />
    </>
  );
}
