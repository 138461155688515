import { Splide, Components, Options } from '@splidejs/splide';

/**
 * Focus color extension
 *
 * @param Splide     - Splide instance
 * @param Components - Collection of splide components
 * @param options    - Options
 */
export default function HideArrowsExtension(
  Splide: Splide,
  Components: Components,
  options: Options
): { mount } {
  const { arrows } = Components.Arrows;
  const perPage: number | undefined = options.perPage;
  const slideLength = Components.Elements.slides.length;

  /**
   * Called when the component is mounted.
   */
  function mount() {
    if (perPage && perPage >= slideLength) hideArrows();
  }

  /**
   * Hide next and previous arrows
   */
  function hideArrows() {
    arrows.next?.classList.add('hidden');
    arrows.prev?.classList.add('hidden');
  }

  return { mount };
}
