import { decimalRound, getHTPrice } from './gtmUtils';

export function getBrand(brand?: string): string {
  return brand?.toLowerCase() || 'afibel';
}

export function getCategory1(category: string, isDirectOrder = false): string {
  return isDirectOrder ? 'commande direct' : category.toLowerCase();
}

export function getCategory2(category: string): string {
  return category.toLowerCase();
}

export function getCategory3(category: string): string {
  return category.toLowerCase();
}

// @TODO - Update after tapbuy
export function getCoupon(): string | undefined {
  return localStorage.getItem('privilegeCode') || undefined;
}

export function getDiscountAti(
  priceAtiOriginal?: number,
  priceAtiAfter?: number
): number | undefined {
  return (
    priceAtiOriginal &&
    priceAtiAfter &&
    decimalRound(priceAtiOriginal - priceAtiAfter)
  );
}

export function getDiscountHT(
  priceHTOriginal?: number,
  priceHTAfter?: number
): number | undefined {
  return (
    priceHTOriginal &&
    priceHTAfter &&
    decimalRound(priceHTOriginal - priceHTAfter)
  );
}

export function getName(name: string): string {
  return name.toLowerCase();
}

export function getPriceHTOriginal(
  price?: number,
  crossedOutPrice?: number
): number | undefined {
  return (
    (price || crossedOutPrice) &&
    getHTPrice((crossedOutPrice ?? price) as number)
  );
}

export function getPriceHTAfter(price?: number): number | undefined {
  return price && getHTPrice(price);
}

export function getPriceAtiOriginal(
  price?: number,
  crossedOutPrice?: number
): number | undefined {
  return crossedOutPrice ?? price;
}

export function getPriceAtiAfter(price?: number): number | undefined {
  return price;
}

export function getProductModel(sku: string): string {
  return sku.slice(0, -3);
}

export function getProductColorModel(sku: string): string {
  return sku.slice(0, -2);
}

export function getCommercialOperationSticker(
  sticker?: string,
  isDirectOrder = false
): string | undefined {
  return isDirectOrder
    ? 'commande direct'
    : sticker?.split('¤')[0].toLowerCase();
}
