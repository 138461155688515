import React, { ForwardedRef, forwardRef } from 'react';

import { replaceByLinkEnv } from 'services/formatter';

type Props = React.HTMLAttributes<HTMLDivElement> & { htmlContent: string };

const InsertHtmlContent = (
  { htmlContent, ...props }: Props,
  ref: ForwardedRef<HTMLDivElement>
): JSX.Element => {
  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{ __html: replaceByLinkEnv(htmlContent) }}
      {...props}
    />
  );
};

export default forwardRef(InsertHtmlContent);
