import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import Carousel from './Carousel';
import PageNotFoundBanner from './PageNotFoundBanner';
import useGtmPageView from 'hooks/gtm/useGtmPageView';
import useEnv from 'hooks/useEnv';
import { fetcher } from 'services/fetcher';
import { getMerchActionProducts } from 'services/merchandising';
import {
  getFormattedFlowAttributeDefinition,
  getFormattedProductDetailToCarouselProduct,
} from 'services/formatter';
import { GTM_PAGE_ERROR } from 'settings/gtm/gtmPageView';
import {
  MAX_PRODUCTS_SearchNoResultDecouvrezAussiProduit,
  MAX_PRODUCTS_SearchNoResultVousAimerezProduit,
} from 'settings/merchandising';
import { SearchNoResult } from 'types/SearchNoResult';

import styles from './PageNotFound.module.scss';

function PageNotFound({ status }: { status?: string }): JSX.Element {
  const { t } = useTranslation();
  const { datetimeApp, urlApiCatalog } = useEnv();

  useGtmPageView(GTM_PAGE_ERROR, '', {
    error: status ?? '404',
    isLoaded: true,
  });

  const renderShippingIdeaCarousel = products => {
    return renderCarousel(
      styles.shoppingIdea,
      t('LABEL_SHOPPING_IDEA'),
      4,
      2,
      products
    );
  };

  const renderAlsoDiscoverCarousel = products => {
    return renderCarousel(
      styles.alsoDiscover,
      t('LABEL_ALSO_DISCOVER'),
      4,
      2,
      products
    );
  };

  const renderCarousel = (
    wrapperClass,
    title,
    productPerPage,
    mobileProductPerPage,
    products
  ) => {
    return (
      <Carousel
        classes={{
          wrapper: wrapperClass,
        }}
        desktopProductPerPage={productPerPage}
        mobileProductPerPage={mobileProductPerPage}
        products={products}
        title={title}
      />
    );
  };

  const { data } = useSWR<SearchNoResult>(
    `${urlApiCatalog}/json/Custom/SearchNoResult.cxml?${datetimeApp}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );

  const attributes = useMemo(() => {
    return getFormattedFlowAttributeDefinition(
      data?.Header?.Controls[1]?.Attributes
    );
  }, [data?.Header?.Controls[1]?.Attributes]);

  const formattedShoppingIdeaProducts = useMemo(() => {
    return getFormattedProductDetailToCarouselProduct(
      getMerchActionProducts(
        data?.Zones[1]?.Controls[0]?.Actions,
        MAX_PRODUCTS_SearchNoResultVousAimerezProduit
      ),
      attributes
    );
  }, [
    data?.Zones[1]?.Controls[0]?.Actions,
    MAX_PRODUCTS_SearchNoResultVousAimerezProduit,
    attributes,
  ]);

  const formattedAlsoDiscoverProducts = useMemo(() => {
    return getFormattedProductDetailToCarouselProduct(
      getMerchActionProducts(
        data?.Zones[2]?.Controls[0]?.Actions,
        MAX_PRODUCTS_SearchNoResultDecouvrezAussiProduit
      ),
      attributes
    );
  }, [
    data?.Zones[2]?.Controls[0]?.Actions,
    MAX_PRODUCTS_SearchNoResultDecouvrezAussiProduit,
    attributes,
  ]);

  return (
    <>
      <Helmet
        meta={[
          {
            name: 'prerender-status-code',
            content: status ?? '404',
          },
        ]}
      />
      <PageNotFoundBanner />
      {formattedShoppingIdeaProducts.length > 0 &&
        renderShippingIdeaCarousel(formattedShoppingIdeaProducts)}
      {formattedAlsoDiscoverProducts.length > 0 &&
        renderAlsoDiscoverCarousel(formattedAlsoDiscoverProducts)}
    </>
  );
}

export default PageNotFound;
