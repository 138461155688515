export const ENV_APP_MODE = process.env.REACT_APP_ENV;
export const ENV_APP_VERSION = process.env.REACT_APP_VERSION;
export const ENV_NEWSLETTER_HIGHTLIGHTING_SIGNUP =
  process.env.REACT_APP_NEWSLETTER_HIGHTLIGHTING_SIGNUP;

export const ENV_COUNTRY = process.env.REACT_APP_COUNTRY;
export const ENV_CURRENCY_LOCALES_CODE =
  process.env.REACT_APP_CURRENCY_LOCALES_CODE;
export const ENV_CURRENCY_FUNDS_CODE =
  process.env.REACT_APP_CURRENCY_FUNDS_CODE;

export const ENV_ISICOM_ENABLE = process.env.REACT_APP_ENABLE_ISICOM;

export const ENV_HTML_SITEMAP_PRODUCTS_URL =
  process.env.REACT_APP_URL_SITEPLAN_HTML_PRODUCTS;

export const ENV_HTML_SITEMAP_CATEGORIES_URL =
  process.env.REACT_APP_URL_SITEPLAN_HTML_CATEGORIES;

export const ENV_HTML_SITEMAP_RELATIONSHIPS_CATEGORY_URL =
  process.env.REACT_APP_URL_MAILLAGE_BLOC_HTML;

export const ENV_API_URL_CATALOG = process.env.REACT_APP_URL_API_CATALOG;
export const ENV_API_URL_CHECKOUT = process.env.REACT_APP_URL_API_CHECKOUT;
export const ENV_API_URL_COLISSIMO = process.env.REACT_APP_URL_API_COLISSIMO;
export const ENV_API_URL_CONTACT = process.env.REACT_APP_URL_API_CONTACT;
export const ENV_API_URL_CUSTOMER = process.env.REACT_APP_URL_API_CUSTOMER;
export const ENV_API_URL_PRODUCT = process.env.REACT_APP_URL_API_PRODUCT;
export const ENV_API_URL_SEARCH = process.env.REACT_APP_URL_API_SEARCH;
export const ENV_API_URL_BROWSER_LOGGER = process.env
  .REACT_APP_URL_API_LOG_BROWSER_DEBUG as string;

export const ENV_MEDIAS_URL_POPIN = process.env
  .REACT_APP_MEDIAS_URL_POPIN as string;
export const ENV_MEDIAS_URL_PRODUCT_SHEET = process.env
  .REACT_APP_MEDIAS_URL_PRODUCT_SHEET as string;

export const ENV_REACT_URL_CUSTOMER = process.env.REACT_APP_URL_CUSTOMER;

export const ENV_MEDIAS_BASE_URL_ACCOUNT =
  process.env.REACT_APP_MEDIAS_URL_ACCOUNT;

export const ENV_MEDIAS_URL_ACCOUNT_CATALOG =
  process.env.REACT_APP_MEDIAS_URL_ACCOUNT_CATALOG;

export const ENV_TWICPICS_BASE_URL = process.env.REACT_APP_TWICPICS;
export const ENV_TWICPICS_URL_MEDIA = process.env.REACT_APP_TWICPICS_MEDIA_URL;

export const ENV_VEESUAL_ENABLE = process.env.REACT_APP_VEESUAL_ENABLE;
export const ENV_VEESUAL_API_KEY = process.env.REACT_APP_VEESUAL_API_KEY;

export const ENV_API_KEY_SYMFONY = process.env
  .REACT_APP_API_SYMFONY_API_KEY as string;

export const ENV_VERIFIED_ADVICE_URL = process.env
  .REACT_APP_VERIFIED_ADVICE_URL as string;

export const ENV_WYSIWYG_OCTIPAS_URL = process.env
  .REACT_APP_WYSIWYG_OCTIPAS_URL as string;

export const env = {
  ENV_PAGE_LANGUAGE: process.env.REACT_APP_PAGE_LANGUE as string,
  ENV_CLOUDFLARE_TURNSTILE_KEY: process.env
    .REACT_APP_CLOUDFLARE_TURNSTILE as string,
  ENV_CLOUDFLARE_TURNSTILE_USER_AGENT_BYPASS:
    process.env.REACT_APP_CLOUDFLARE_TURNSTILE_USER_AGENT_BYPASS,
  ENV_MEDIAS_URL_PAGES: process.env.REACT_APP_MEDIAS_URL_PAGES as string,
};
