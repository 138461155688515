import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Image from 'components/Common/Medias/Image';
import { getUri } from 'services/generic';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { CATEGORY_IMAGE_PRODUCT_HEIGHT } from 'settings/imageSizes';
import { DefaultCarouselProductType } from 'types/Carousel';

import styles from './ProductCarousel.module.scss';

export type Props = {
  classes?: CarouselProductClassesType;
  onClick?: () => void;
  product: DefaultCarouselProductType;
};

type CarouselProductClassesType = {
  shortLabel?: string;
  thumbnail?: string;
};

export default function ProductCarousel({
  classes,
  onClick,
  product: {
    commercialOperationSticker,
    metaMarque,
    shortLabel,
    url,
    visuelFace,
  },
}: Props): JSX.Element {
  const opeco = commercialOperationSticker?.split('¤');

  function handleClick(): void {
    scrollTo(0, 0);
    localStorage.setItem('previous-url', '');
    onClick?.();
  }

  function renderTopSticker(): JSX.Element {
    if (!opeco || opeco.length < 2) {
      return <></>;
    }

    return (
      <div className={styles.topSticker} id="topSticker">
        <span
          className={classNames(styles.sticker, 'noselect')}
          style={{
            background: opeco[1] || '',
          }}
        >
          {opeco[0]}
        </span>
      </div>
    );
  }

  return (
    <Link
      className={styles.productContainer}
      to={getUri(url)}
      onClick={handleClick}
    >
      <div>
        {renderTopSticker()}
        <Image
          alt={shortLabel}
          className={classNames(styles.productThumbnail, classes?.thumbnail)}
          ratio={`400/${CATEGORY_IMAGE_PRODUCT_HEIGHT.toString()}`}
          src={`${ENV_TWICPICS_URL_MEDIA}${visuelFace}`}
        />
      </div>
      <div className={classNames(styles.shortLabel, classes?.shortLabel)}>
        {metaMarque ? `${metaMarque}® - ${shortLabel}` : shortLabel}
      </div>
    </Link>
  );
}
