import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { userContext } from 'contexts/userContext';
import { useCustomer } from 'hooks/useCustomer';
import useEnv from 'hooks/useEnv';
import { useMediaServer } from 'hooks/useMediaServer';
import { apiUrls } from 'services/api-urls';
import { fetcherWithBody } from 'services/fetcher';
import { replaceByLinkEnv } from 'services/formatter';
import { getRoutesUri, getUri } from 'services/generic';
import { gtmAfiEventLab } from 'services/gtm';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { isValidEmail as isValidEmailFnc } from 'services/validation';
import { ENV_APP_VERSION, ENV_VERIFIED_ADVICE_URL } from 'settings/env';
import { CustomerExistsType } from 'types/Customer';

import styles from './AfibelFooter.module.scss';

export default function AfibelFooter(): JSX.Element | null {
  const { pathname } = useLocation();
  const { gtmUserCookie } = useContext(userContext);
  const { dockerDevEnv, mediaFooter } = useEnv();

  const { t } = useTranslation();
  const { customer } = useCustomer();
  const navigate = useNavigate();
  const footer = useMediaServer(mediaFooter + '/footer.html');

  if (!footer.content) {
    return null;
  }

  function displayErrorMessage() {
    const div = document.createElement('div');
    div.setAttribute('id', 'newsletter-error');
    const text = document.createTextNode(t('LABEL_ERROR_INVALID_EMAIL'));
    div.appendChild(text);
    const formNewsletter = (document.getElementById(
      'encart_news'
    ) as HTMLInputElement).form;
    const newsletterInput = document.getElementById('newsletter');
    formNewsletter?.insertAdjacentElement('afterend', div);
    div.style.color = '#e50003';
    newsletterInput?.classList.add('newsletter-error');
  }

  function handleOnClickLinks(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const { target } = ev;

    if (target.tagName.toLowerCase() === 'img') {
      if (target.closest('.new_inf_social') || target.closest('.oct_social')) {
        pushGtmEvent({
          event: '_footer_click',
          category: 'footer',
          element: 'suivez-nous',
          detail: target.alt.split(' ')[0],
        });
      }
      if (
        target.closest('.new_inf_footer-catalog') ||
        target.closest('.oct_footer-catalog')
      ) {
        gtmAfiEventLab(
          pathname,
          'afiEvent',
          'Footer',
          'Départ demande catalogue',
          gtmUserCookie
        );
      }
      if (target.parentNode.href) {
        window.open(target.parentNode.href);
      }
    }
    if (target.href) {
      // Footer's first 3 columns
      if (target.closest('.new_inf_col_links')) {
        if (
          target.className.toLowerCase() ===
          'new_inf_links_accessibility-download'
        ) {
          pushGtmEvent({
            event: '_footer_click',
            category: 'footer',
            element: 'accessibilité',
          });
        } else if (
          target.className.toLowerCase() === 'new_inf_links_contact_mail' ||
          target.className.toLowerCase() === 'oct_cta-contact'
        ) {
          pushGtmEvent({
            event: '_footer_click',
            category: 'footer',
            element: 'contactez-nous par mail',
          });
        } else {
          pushGtmEvent({
            event: '_footer_click',
            category: 'footer',
            element: 'Nos conseils',
            detail: target.innerText.toLowerCase(),
          });
        }
      }
      // groupe damartex
      if (target.closest('.new_inf_footer-groupe-damart')) {
        pushGtmEvent({
          event: '_footer_click',
          category: 'footer',
          element: 'groupe damartex',
          detail: target.innerText.toLowerCase(),
        });
      }
      // liens supplémentaires
      if (target.closest('.new_inf_bot_footer-horizontal-links')) {
        pushGtmEvent({
          event: '_footer_click',
          category: 'footer',
          element: 'liens supplémentaires',
          detail: target.innerText.toLowerCase(),
        });
      }
      // club afibel
      if (target.closest('.new_inf_footer_inter')) {
        pushGtmEvent({
          event: '_footer_click',
          category: 'footer',
          element: 'club afibel',
          detail: target.innerText.toLowerCase(),
        });
      }

      if (
        target.closest('.new_inf_footer-catalog') ||
        target.closest('.oct_footer-catalog')
      ) {
        gtmAfiEventLab(
          pathname,
          'afiEvent',
          'Footer',
          'Départ demande catalogue',
          gtmUserCookie,
          target.title
        );
      }
      window.location = target.href;
    }
  }

  function handleCustomerNewsletter(emailValue) {
    if (customer?.isLoggedIn) {
      if (emailValue === customer?.email) {
        fetcherWithBody(apiUrls.getCustomerUpdate(), {
          email: emailValue,
          mailOption: 'O',
          evt: 'newsletter',
          sendMailNewsletter: true,
        }).then(() => {
          navigate(getRoutesUri('customerAccountNewsletter') as string, {
            state: { mailOption: true },
          });
        });
      } else {
        navigate(getRoutesUri('customerAccountNewsletter') as string);
      }

      return;
    }

    fetcherWithBody(apiUrls.getCustomerExists(), {
      email: emailValue,
    }).then((customer: CustomerExistsType) => {
      if (!customer.exists) {
        navigate(getUri(`/newsletter/subscriber`), {
          state: {
            emailValue,
            mailOption: '1',
            partOption: '0',
            smsOption: '0',
          },
        });
      } else {
        navigate(getRoutesUri('customerLogin') as string, {
          state: { mandatoryLogInNewsletter: true },
        });
        window.scrollTo(0, 0);
      }
    });
  }

  function handleOnClickNewsletter(ev, enter = false) {
    ev.preventDefault();
    ev.stopPropagation();
    if (
      ['button', 'span'].includes(ev.target.nodeName.toLowerCase()) ||
      enter
    ) {
      const emailValue = (document?.getElementById(
        'newsletter'
      ) as HTMLInputElement)?.value;
      document.getElementById('newsletter-error')?.remove();
      document
        .getElementById('newsletter')
        ?.classList.remove('newsletter-error');
      pushGtmEvent({
        event: '_newsletter_signup',
        category: 'footer',
      });
      if (isValidEmailFnc(emailValue)) {
        handleCustomerNewsletter(emailValue);
        return;
      }
      displayErrorMessage();
    }
  }

  function handleOnClick(ev, enter = false) {
    ev.preventDefault();
    ev.stopPropagation();
    const { target } = ev;
    const targetId = target.id;

    if (targetId === 'encart_news' || enter) {
      handleOnClickNewsletter(ev, enter);
    } else {
      handleOnClickLinks(ev);
    }
  }

  return (
    <>
      <VerifiedAdvice />
      {'1' === dockerDevEnv && (
        <link rel="stylesheet" href={`${mediaFooter}/footer.css`} />
      )}
      <div className={classNames('page-bottom', styles.wrapper)}>
        {footer && (
          <footer className="page-footer">
            <div className="content">
              <div
                className="widget block block-static-block"
                dangerouslySetInnerHTML={{
                  __html: replaceByLinkEnv(footer.content),
                }}
                onClick={ev => handleOnClick(ev)}
                onKeyPress={ev => {
                  if (ev.key == 'Enter') {
                    handleOnClick(ev, true);
                  }
                }}
              />
            </div>
            <div id="app_version" className="hidden">
              V{ENV_APP_VERSION}
            </div>
          </footer>
        )}
      </div>
    </>
  );
}

function VerifiedAdvice(): JSX.Element {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = ENV_VERIFIED_ADVICE_URL;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
}
