import { ENV_APP_MODE } from './env';

// Types
export const NUMBER_REGEX = /^[0-9 ]*$/;
export const LETTER_REGEX = /^[a-zA-Z-'à ]*$/;

// Post code
export const POSTCODE_FR_REGEX = /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/;
export const POSTCODE_UK_REGEX = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;

// Phone
export const CELLPHONE_FR_REGEX = /^(?=06|07)(\d\s?)/;
export const CELLPHONE_UK_REGEX = /^(?=07)(\d\s?)/;
export const LANDLINE_FR_REGEX = /^0(?![678])(\d\s?)/;
export const LANDLINE_UK_REGEX = /^(?=01|02)(\d\s?)/;
export const LANDLINE_FR_LENGTH_REGEX = /^(?:\s*\d){10}\s*$/;
export const LANDLINE_UK_LENGTH_REGEX = /^(?:\s*\d){10,11}\s*$/;
export const CELLPHONE_FR_LENGTH_REGEX = /^(?:\s*\d){10}\s*$/;
export const CELLPHONE_UK_LENGTH_REGEX = /^(?:\s*\d){11}\s*$/;

// Customer
export const CUSTOMER_NUMBER_REGEX = /^[0-9\d]{8}$/;

// Order
export const ORDER_NUMBER_REGEX = /^\d{1,20}$/;

// Personal details
export const LASTNAME_REGEX = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF' -]+$/;
export const FIRSTNAME_REGEX = /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF' -]+$/;
export const FIELDS_REGEX = {
  number: NUMBER_REGEX,
  letter: LETTER_REGEX,
};

// Product page
export const EDIT_URL_REGEX = /\/edit\/[0-9]+$/;
export const EXTRACT_UNIQ_MEDIA_CODE = /.*_(?<codeMedia>.*)_(?:\S).*[.].*$/;

// Page type
export const CATEGORY_PAGE_REGEX = /\/C-\d{5}/;
export const HOME_PAGE_REGEX = /.*\/(fr|uk)\/$/;
export const PRODUCT_PAGE_REGEX = /\/P-[A-Za-z0-9]{8,10}-/;
export const SEARCH_PAGE_REGEX = /\/Recherche\/|\/Search\//;

export const EMAIL_REGEX =
  'prod' === ENV_APP_MODE
    ? /^(?=.{1,200}$)[A-Za-z0-9\-_.]+@[A-Za-z0-9\-_.]+\.[A-Za-z0-9\-_.]+$/
    : /^(?=.{1,200}$)[A-Za-z0-9\-_.+]+@[A-Za-z0-9\-_.]+\.[A-Za-z0-9\-_.]+$/;

export const SPECIALS_CHARACTERS_REGEX = new RegExp(
  '²~-_^@"&°+=€¨^£$%µ*<>,?.;:§!#{(|\\[])}'
);

export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[à-öø-þÀ-ÖØ-Þ²~\-_^@"&°+=€¨^£$%µ*<>,?.;:§!#{(|\\[\])}]).{8,32}$/gm;
