import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import ProductCarousel from './ProductCarousel/ProductCarousel';
import HideArrowsExtension from './SliderExtensions/HideArrowsExtension';
import { DefaultCarouselProductType } from 'types/Carousel';

import styles from './ProductsCarousel.module.scss';

type ComponentType = {
  productId: number;
};

export type ProductsCarouselProps<T> = {
  classes?: {
    wrapper?: string;
    carouselContainer?: string;
    nextArrow?: string;
    prevArrow?: string;
  };
  products: T[];
  renderProduct?: (product: T) => JSX.Element;
  splideOptions?: Options;
  title?: string;
};

const DEFAULT_PER_PAGE = 6;

export default function ProductsCarousel<
  T extends ComponentType = DefaultCarouselProductType
>({
  classes,
  products,
  renderProduct,
  splideOptions,
  title,
}: ProductsCarouselProps<T>): JSX.Element {
  const windowWidth = window.innerWidth;
  const scrollbarWidth = windowWidth - document.documentElement.clientWidth;
  const wrapperStyle = {
    '--scrollbarWidth': scrollbarWidth + 'px',
  } as React.CSSProperties;

  const options = useMemo(() => {
    return {
      ...{
        margin: 0,
        rewind: false,
        perMove: 1,
        perPage: DEFAULT_PER_PAGE,
        gap: '10px',
        pagination: false,
        classes: {
          arrow: classnames('splide__arrow', styles.arrow),
          next: classnames(
            'splide__arrow--next',
            styles.nextArrow,
            classes?.nextArrow
          ),
          prev: classnames(
            'splide__arrow--prev',
            styles.prevArrow,
            classes?.prevArrow
          ),
        },
      },
      ...(splideOptions || {}),
    };
  }, [splideOptions]);

  return (
    <div
      className={classnames(styles.wrapper, classes?.wrapper)}
      style={wrapperStyle}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      <div
        className={classnames(
          styles.carouselProductsContainer,
          classes?.carouselContainer
        )}
      >
        <Splide
          options={options}
          extensions={{ hideArrows: HideArrowsExtension }}
        >
          {products.map(product => {
            const { productId } = product;
            return (
              <SplideSlide key={productId}>
                {renderProduct ? (
                  renderProduct(product)
                ) : (
                  <ProductCarousel
                    product={(product as unknown) as DefaultCarouselProductType}
                  />
                )}
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
}
