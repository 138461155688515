import { ProductDetail } from 'types/Controls/ProductSheet';
import {
  CarouselProductType,
  Product,
  ProductDataListing,
  ProductDataSku,
  ProductDynamicAttributesType,
} from 'types/Product';
import { DefaultCarouselProductType } from 'types/Carousel';
import { Header } from 'types/Header';
import { FormattedFlowAttributeType } from 'types/formatter';
import { getDataFromAttributes } from './data';
import { getRoutesUri } from './generic';
import useCurrentCountry from 'hooks/useCurrentCountry';
import {
  ENV_COUNTRY,
  ENV_CURRENCY_FUNDS_CODE,
  ENV_CURRENCY_LOCALES_CODE,
  ENV_TWICPICS_BASE_URL,
} from 'settings/env';

type LowercaseKeys<T> = {
  [K in keyof T as Lowercase<string & K>]: T[K];
};

const { isUk } = useCurrentCountry();

export function formatPrice(
  price: string | number | undefined,
  stripIfInteger = false
): string {
  if (undefined === price || '' === price) {
    return '';
  }

  const priceParsed = parseFloat(`${price}`);
  if (isNaN(priceParsed)) {
    return '';
  }

  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: ENV_CURRENCY_FUNDS_CODE,
  };

  if (stripIfInteger && Number.isInteger(priceParsed)) {
    options.maximumFractionDigits = 0;
    options.minimumFractionDigits = 0;
  }

  return new Intl.NumberFormat(ENV_CURRENCY_LOCALES_CODE, options).format(
    priceParsed
  );
}

/**
 * Get cara Label, MainName and Metadata associated to attribute
 * @param attributes
 * @returns
 */
export function getFormattedFlowAttributeDefinition(
  attributes: Header['Controls'][1]['Attributes'] | undefined
): FormattedFlowAttributeType {
  if (!attributes) {
    return {};
  }
  return attributes.reduce(
    (accumulator, attribute) => ({
      ...accumulator,
      [attribute.Code]: {
        Label: attribute.Label,
        MainName: attribute.MainName,
        Metadata: attribute.Metadata,
      },
    }),
    {}
  );
}

/**
 * Get Label metadata associated to its value
 * @param metaData
 * @returns
 */
export function getFormattedMetaData(
  metaData: { Label: string; Value: string }[] | undefined
): { [Label: string]: { Value: string } } {
  if (!metaData) {
    return {};
  }
  return metaData.reduce(
    (accumulator, metaData) => ({
      ...accumulator,
      [metaData.Label]: {
        Value: metaData.Value,
      },
    }),
    {}
  );
}

/**
 * Format products (ProductDetail type) to CarouselProductType
 * @param products
 * @param attributes
 * @returns
 */
export function getFormattedProductDetailToCarouselProduct(
  products: ProductDetail[] = [],
  attributes: {
    [key: string]: {
      Label: string;
      MainName: string;
    };
  }
): CarouselProductType[] {
  return products?.map(product => {
    const productInfo = getDataFromAttributes(
      product.Attributes,
      attributes
    ) as ProductDataListing;
    return {
      productId: product.Id,
      visuelFace: productInfo.imgfacepivot,
      price: productInfo.prix,
      discountPrice: productInfo.prixbarre,
      uniquePrice: productInfo.prixunique,
      shortLabel: productInfo.libelle,
      url: product.Url,
      color: productInfo.couleur,
      size: productInfo.libelletaille,
      metaMarque: productInfo.metamarque,
      modelName: productInfo.nommodele,
      stock: productInfo.disponibilite,
      family: productInfo.codelibellefamille,
      subfamily: productInfo.codelibellessfamille,
      subuniverse: productInfo.subuniverse,
      universe: productInfo.universe,
      discountPercentage: productInfo.decote,
      commercialOperationSticker: productInfo.stickeropeco,
    };
  });
}

/**
 * Format products (ProductDetail type) to DefaultCarouselProductType
 */
export function getCarouselProductsData(
  products: ProductDynamicAttributesType[] = []
): DefaultCarouselProductType[] {
  return products?.map(product => {
    return {
      productId: product.id,
      visuelFace: product.attributes.imgFacePivot.Value,
      shortLabel: product.attributes.libelle.Value,
      url: product.url,
      commercialOperationSticker: product.attributes.stickerOpeco.Value,
      metaMarque: product.attributes.metaMarque.Value,
    };
  });
}

export function getProductColors(
  attributes: { [key: string]: { Label: string; MainName: string } },
  skus: Product['Zones'][0]['Controls'][2]['Skus']
): { [colorLabel: string]: { swatch: string; url: string } } {
  const colors = {};
  let minPrice = 1000000;
  skus?.forEach(sku => {
    const skuData = getDataFromAttributes(
      sku.Attributes,
      attributes
    ) as ProductDataSku;
    const {
      couleur: color,
      couleurstandard: baseColor,
      pastille: swatch,
      prix: price,
    } = skuData;
    if (!Object.keys(colors).includes(color)) {
      minPrice = 1000000;
    }
    if (parseInt(price) < minPrice) {
      minPrice = parseInt(price);
      colors[color] = {
        swatch: swatch ? swatch : baseColor,
        url: sku.Url,
      };
    }
  });

  return colors;
}

export function objectKeysToLowerCase<T extends { [key: string]: unknown }>(
  object: T
): LowercaseKeys<T> {
  return Object.keys(object).reduce((acc, key) => {
    return {
      ...acc,
      [formatMainName(key)]: object[key],
    };
  }, {} as LowercaseKeys<T>);
}

export function formatMainName(mainName: string): Lowercase<string> {
  return mainName?.toLowerCase().replace('_', '');
}

/**
 * Format Date for ReviewsList
 * @returns
 */
export function getFormattedDate(date: string): string {
  return new Date(date).toLocaleDateString(ENV_CURRENCY_LOCALES_CODE, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
}

/**
 * Format Date for ReviewsList
 * @returns
 */
export function getProductReviewsFormattedDate(date: string): string {
  return new Date(date).toLocaleDateString(ENV_CURRENCY_LOCALES_CODE, {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
}

export function capitalize(label: string): string {
  return label.slice(0, 1).toUpperCase() + label.slice(1).toLocaleLowerCase();
}

export function replaceByLinkEnv(
  str: string,
  additionalReplace?: Record<string, string>
): string {
  const replaces = {
    '@@base_url@@': `${ENV_TWICPICS_BASE_URL}/${ENV_COUNTRY}`,
    '@@base_web_url@@': `/${ENV_COUNTRY}`,
    '@@siteplan_html_categories_url@@': getRoutesUri(
      `categorySitemap`
    ) as string,
    '@@siteplan_html_products_url@@': getRoutesUri(`productSitemap`) as string,
    ...additionalReplace,
  };

  return Object.entries(replaces).reduce((text, [property, replacement]) => {
    return text.replace(new RegExp(property, 'g'), replacement);
  }, str);
}

export function formatDecimalPrice(price: string): string {
  if (price.includes(',')) {
    const splitPrice = price.split(',');
    price = splitPrice[0] + ',' + splitPrice[1].slice(0, 2);
  } else if (price.includes('.')) {
    const splitPrice = price.split('.');
    price = splitPrice[0] + '.' + splitPrice[1].slice(0, 2);
  }

  return price;
}

export function removePageParams(uri: string): string {
  const splitUri = uri.split('/');
  const lastUriPart = splitUri[splitUri.length - 1];
  const regex = new RegExp('page(\\d+)', 'i');
  const match = lastUriPart.match(regex);

  if (match) {
    splitUri.splice(-1);
    const newUri = splitUri.join('/');

    return newUri;
  }

  return uri;
}

export function replacePhoneNumberWithSpace(phone: string): string {
  return isUk
    ? phone.replace(/(\d{5})(\d{6})/, '$1 $2').replace(/ +/g, ' ')
    : phone.replace(/(\d{2})(?=\d)/g, '$1 ').replace(/ +/g, ' ');
}

export function replacePhoneNumberWithoutSpace(phone: string): string {
  return phone.replace(/\s/g, '');
}

export function formatPercentage(
  percent: number,
  divide = true,
  locale = ENV_CURRENCY_LOCALES_CODE
): string {
  const percentNumber = !divide ? percent * 100 : percent;
  const percentValue = divide ? percent / 100 : percent;

  const options: Intl.NumberFormatOptions = {
    style: 'percent',
  };

  if (!Number.isInteger(+percentNumber)) {
    options.maximumFractionDigits = 2;
    options.minimumFractionDigits = 2;
  }

  return new Intl.NumberFormat(locale, options).format(+percentValue);
}

export function formatDiscountPercentage(
  percent: number,
  divide = true,
  locale = ENV_CURRENCY_LOCALES_CODE
): string {
  return formatPercentage(percent, divide, locale).replace(/\s*(%)$/, '%');
}
